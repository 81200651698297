import { HashLink } from 'react-router-hash-link'
import { motion } from 'framer-motion'

import stonks from '../../images/landing/stonks.jpeg'

const AiClubSection = () => {
  return (
    <div id='aiclub' className='relative flex scroll-m-24 flex-col overflow-hidden'>
      <div className='container grid lg:grid-cols-2 lg:gap-16'>
        <div className='h-[300px] overflow-hidden lg:relative lg:order-1 lg:h-full'>
          <motion.img
            src='/img/aiclub-graphic-2.jpeg'
            alt='ai_club'
            className='absolute right-0 top-10 h-[200px] rounded-xl lg:-right-10 lg:top-32 lg:h-[400px] lg:rounded-2xl'
            initial={{ opacity: 0, scale: 0.5, rotate: -25, translateX: 150 }}
            whileInView={{ opacity: 1, scale: 1, translateX: 25 }}
            transition={{ type: 'spring', stiffness: 150, damping: 20, delay: 0.25, duration: 0.75 }}
            viewport={{ once: true }}
          />
        </div>
        <div className='flex flex-col items-start justify-center py-8 lg:order-2 lg:py-16'>
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              type: 'spring',
              stiffness: 150,
              damping: 20,
              delay: 0.15,
              duration: 2
            }}
            viewport={{ once: true }}
            className='mb-4 bg-gradient-to-bl from-sky-500 via-sky-500 to-primary bg-clip-text text-4xl font-bold text-transparent sm:text-6xl xl:mb-8 xl:text-8xl'
          >
            AI Supported Investments
          </motion.h2>
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              type: 'spring',
              stiffness: 150,
              damping: 20,
              delay: 0.15,
              duration: 2
            }}
            viewport={{ once: true }}
            className='mb-4 text-lg xl:mb-10 xl:text-2xl'
          >
            Capitavio offers its customers an easy access to invest in an intelligent managed trading system supported
            by artificial intelligence. Customers can invest in a trading system which generated profitable results in
            the past. The chart shows the result of that trading strategy versus investing in S&P 500 index.
          </motion.p>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              type: 'spring',
              stiffness: 150,
              damping: 20,
              delay: 0.15,
              duration: 2
            }}
            viewport={{ once: true }}
            className='rounded-full bg-primary px-10 py-3 text-lg font-medium text-white transition-all hover:opacity-95'
          >
            <HashLink to={'#home'}>Join Waitlist</HashLink>
          </motion.div>
        </div>
      </div>
      <div className='absolute bottom-0 left-0 right-0 top-0 -z-10 grid lg:grid-cols-2 lg:gap-16'>
        <div
          className='h-[300px] bg-cover bg-no-repeat lg:order-1 lg:h-full'
          style={{ backgroundImage: `url(${stonks})` }}
        ></div>
        <div className='lg:order-2'></div>
      </div>
    </div>
  )
}

export default AiClubSection
