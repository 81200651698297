import { Link, useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { cn } from '../../utils/cn-merge'

import { ReactComponent as Footer_stroke } from '../../images/landing/footer-stroke.svg'
import { ReactComponent as Paper_plane } from '../../images/landing/paper-plane.svg'
import { ReactComponent as Logo } from '../../images/logo.svg'

const Footer = () => {
  const location = useLocation()

  return (
    <div className='flex flex-col overflow-hidden bg-zinc-900 pt-10 text-white'>
      <div className='container relative'>
        <Footer_stroke className='pointer-events-none absolute right-60 top-48 hidden -rotate-[12deg] text-primary lg:block' />
        <Paper_plane className='absolute right-52 top-8 hidden text-primary lg:block' />
        <div className='mb-10 flex flex-col items-center space-y-4 md:mb-24 md:items-stretch md:space-y-0'>
          <span className='text-2xl text-zinc-400'>Navigation</span>
          <div className='flex items-center justify-between text-lg'>
            <nav className='flex flex-col items-center space-y-1 text-white sm:flex-row sm:space-x-4 sm:space-y-0 [&>.active]:text-primary'>
              <HashLink to={'/#'} className={cn({ active: location.hash === '' })}>
                Home
              </HashLink>
              <HashLink to={'/#products'} className={cn({ active: location.hash === '#products' })}>
                Products
              </HashLink>
              <HashLink to={'/#banking'} className={cn({ active: location.hash === '#banking' })}>
                Banking
              </HashLink>
              <HashLink to={'/#aiclub'} className={cn({ active: location.hash === '#aiclub' })}>
                AI Club
              </HashLink>
              <HashLink to={'/#whyus'} className={cn({ active: location.hash === '#whyus' })}>
                Why Us
              </HashLink>
              <HashLink to={'/#experts'} className={cn({ active: location.hash === '#experts' })}>
                Experts
              </HashLink>
            </nav>
            <HashLink
              to={'/#home'}
              className='hidden rounded-full bg-white px-10 py-3 text-lg font-medium text-black transition-all hover:opacity-95 md:block'
            >
              Join Waitlist
            </HashLink>
          </div>
        </div>
        <div className='mb-16 flex flex-col items-center sm:mb-24 sm:items-start'>
          <Logo className='w-36' />
          <div className='flex w-full flex-col items-center justify-between space-y-4 text-sm sm:flex-row sm:space-y-0 sm:text-base'>
            <span className='sm:mr-auto'>Copyright © {new Date().getFullYear()} Capitavio. All rights reserved.</span>
            <div className='flex items-center space-x-4 sm:space-x-8'>
              <Link to='/policy' className='hover:text-primary'>
                Privacy Policy
              </Link>
              <Link to='/terms' className='hover:text-primary'>
                Terms of Use
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
