import AiClubSection from './aiclub-section'
import BankingSection from './banking-section'
import ExpertsSection from './experts-section'
import HomeSection from './home-section'
import ProductsSection from './products-section'
import WhyUsSection from './whyus-section'

import CookiesPopup from './cookies-popup'
import Header from './header'
import Footer from './footer'

const Landing = () => {
  return (
    <div className='flex flex-col font-Open-Sans font-thin'>
      <Header />
      <HomeSection />
      <ProductsSection />
      <BankingSection />
      <AiClubSection />
      <WhyUsSection />
      <ExpertsSection />
      <CookiesPopup />
      <Footer />
    </div>
  )
}

export default Landing
